import { Card, FlexBox, Typography } from "@vp/swan";
import { useLogger, useSwanStyleKeys, useUserContext } from "@vp/ubik-context";

interface vatID {
  value: string;
}

export interface Props {
  vatIDs: vatID[];
}

export const Fragment = ({ vatIDs }: Props) => {
  const logger = useLogger();
  const { locale } = useUserContext();
  useSwanStyleKeys(["core"]);

  if (locale !== "en-gb") {
    return <></>;
  }

  logger.log("Rendering fragment for a locale", locale);

  return (
    <FlexBox>
      <Card style={{ width: "100%" }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          VAT IDs
        </Typography>
        {vatIDs.map((vatID) => (
          <Typography key={vatID.value} mb={1}>
            {vatID.value}
          </Typography>
        ))}
      </Card>
    </FlexBox>
  );
};
